.background {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  text-align: center;

  .error {
    font-size: 1rem;
    margin-top:20px;
    color:darkred
  }
}


.room {
  background-color:var(--backgroundColour); 
  padding: 0;
 
 
  @media(min-width: 900px){
    width: 800px;
    height: 75vh;
    position: relative;

  }

  @media(max-width: 899px){
    //  height: 100vh;
  }
 

  .header {
    background: var(--headerColour);
    margin: 0;
    padding: 10px;
    position:sticky;
    font-size: 1.25em;
    font-weight: 700;
    z-index: 1001;
    display:flex;

    @media(max-width: 899px){
      position:fixed;
      width: 100%;
   }
  }

  .characterImage{
    height: 45px;
    width: 45px;
    border-radius: 999px;
    margin-right:10px;
  }

  .characterName {
    line-height: 48px;
  }
  
  a:link
  {
    color: var(--linkColour);
  }
  
  a:active {
    color: var(--linkColour);
  }
  
  a:visited {
    color: var(--linkColour);
  }


  .chat {

    .messages {

      > div {
        padding: 30px 30px 20px; 
        display: flex;
        flex-direction: column;
        bottom: 0;

        @media(max-width: 575px){
          padding: 20px;
        }

        @media(min-width: 900px){
          position: absolute;
        }


        > div {
          border-bottom: 1px solid #eee;
        }

        .them {
          background:	var(--characterBubbleColour);
          color:	var(--characterTextColour);
          padding: 10px;
          border-radius: 10px;
          max-width: 65%;
          position: relative;
          margin-top:15px;
          align-self: flex-start;
          word-wrap: break-word;
        }

        .them:before {
          content: "";
          width: 0px;
          height: 0px;
          position: absolute;
          border-right: 7px solid var(--characterBubbleColour);
          border-left: 10px solid transparent;
          border-top: 12px solid var(--characterBubbleColour);
          border-bottom: 10px solid transparent;
          left: -8px;
          top: 0px;
          border-radius: 2px 0 0 0;
        }

        .you {
          background:	var(--userBubbleColour);
          color:	var(--userTextColour);
          padding: 10px;
          border-radius: 10px;
          max-width: 60%;
          justify-self: right;
          align-self: flex-end;
          text-align: right;
          margin-top:15px;
          position: relative;
          word-wrap: break-word;
        }

        .you:before {
          content: "";
          width: 0px;
          height: 0px;
          position: absolute;
          border-left: 7px solid var(--userBubbleColour);
          border-right: 10px solid transparent;
          border-top: 10px solid var(--userBubbleColour);
          border-bottom: 10px solid transparent;
          right: -8px;
          top: 0px;
          border-radius: 2px;
        }
      }
    }

    .messageWrap {
      background: var(--headerColour);;
      padding: 10px  20px;
      position:absolute;
      bottom:0;
      right:0;
      left: 0;

      @media(max-width: 899px){
        padding:10px 20px 20px;
        position:fixed;
      }
    }

    .messageImage {
      width: 100%;
    }

    .sendIconWrap {
      margin-left: 15px;

      :hover {
        cursor: pointer;
      }
      > svg {
      fill:grey;
        margin-top: 11px;
      }
    }


    .message-input {
      display: flex;
      justify-content: center;

      ::placeholder {
        color: black;
        opacity: 1;
      }

      input {
        border-radius: 5px;
        border: 1px solid lightGray;
        padding-left: 10px;
        height: 40px;
        font-size: 16px;



        @media(max-width: 575px){
          width:100%
        }

        @media(min-width: 576px){
          width:65%
        }
      }
    }

  }
}
